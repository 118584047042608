.login-form-container {
    background-color: #afcaff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.submit-button {
    background-color: white;
    color: blue;
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.loginForm {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.loginInput {
    border-color: black;
    border-width: 1px;
    padding: 10px;
    border-radius: 5px;
    /*border: none;*/
    font-size: 18px;
}

.submit-button {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    cursor: pointer;
}